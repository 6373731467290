@import "./mixins";
@import "./variables";

.#{$prefix} {
  &-text {
    &-center {
      text-align: center;
    }
    &-left {
      text-align: left;
    }
    &-right {
      text-align: right;
    }
  }
}

@include breakpoint(mobile) {
  .#{$prefix}-sm {
    &-text {
      &-center {
        text-align: center;
      }
      &-left {
        text-align: left;
      }
      &-right {
        text-align: right;
      }
    }
    &-p-20 {
      padding: 20px;
    }
  }
}

@include breakpoint(tablet) {
  .#{$prefix}-md {
    &-text {
      &-center {
        text-align: center;
      }
      &-left {
        text-align: left;
      }
      &-right {
        text-align: right;
      }
    }
  }
}

@include breakpoint(bigTablet) {
  .#{$prefix}-lg {
    &-text {
      &-center {
        text-align: center;
      }
      &-left {
        text-align: left;
      }
      &-right {
        text-align: right;
      }
    }
  }
}

@include breakpoint(desktop) {
  .#{$prefix}-xl {
    &-text {
      &-center {
        text-align: center;
      }
      &-left {
        text-align: left;
      }
      &-right {
        text-align: right;
      }
    }
  }
}

@include breakpoint(bigscreen) {
  .#{$prefix}-2xl {
    &-text {
      &-center {
        text-align: center;
      }
      &-left {
        text-align: left;
      }
      &-right {
        text-align: right;
      }
    }
  }
}

.#{$prefix} {
  &-fw-400 {
    font-weight: 400 !important;
  }
  &-fw-500 {
    font-weight: 500 !important;
  }
  &-fw-700 {
    font-weight: 700 !important;
  }
  &-fw-normal {
    font-weight: normal !important;
  }
  &-fw-bold {
    font-weight: bold !important;
  }
  &-lh-1 {
    line-height: 1;
  }
  &-lh-1_3 {
    line-height: 1.3;
  }
  &-lh-1_4 {
    line-height: 1.4;
  }
  &-lh-1_6 {
    line-height: 1.6;
  }
}
