@import "./mixins";
@import "./variables";

.#{$prefix} {
  &-border-circle {
    border-radius: 50%;
  }

  &-border-8 {
    border-radius: 8px;
  }

  &-border-4 {
    border-radius: 4px;
  }
}

@for $i from 1 through 100 {
  .#{$prefix} {
    &-w-#{$i} {
      width: calc($i * 1%) !important;
    }
  }
  .#{$prefix} {
    &-h-#{$i} {
      height: calc($i * 1vh) !important;
    }
  }
}

.#{$prefix} {
  &-outline-none {
    outline: none !important;
  }

  &-w-full {
    width: 100%;
  }
}

@include breakpoint(mobile) {
  .#{$prefix}-sm {
    &-border-circle {
      border-radius: 50%;
    }

    &-border-8 {
      border-radius: 8px;
    }

    &-border-4 {
      border-radius: 4px;
    }
  }

  @for $i from 1 through 100 {
    .#{$prefix}-sm {
      &-w-#{$i} {
        width: calc($i * 1%) !important;
      }
    }
    .#{$prefix} {
      &-h-#{$i} {
        height: calc($i * 1vh) !important;
      }
    }
  }
}

@include breakpoint(tablet) {
  .#{$prefix}-md {
    &-border-circle {
      border-radius: 50%;
    }

    &-border-8 {
      border-radius: 8px;
    }

    &-border-4 {
      border-radius: 4px;
    }
  }

  @for $i from 1 through 100 {
    .#{$prefix}-md {
      &-w-#{$i} {
        width: calc($i * 1%) !important;
      }
    }
    .#{$prefix} {
      &-h-#{$i} {
        height: calc($i * 1vh) !important;
      }
    }
  }
}

@include breakpoint(bigTablet) {
  .#{$prefix}-lg {
    &-border-circle {
      border-radius: 50%;
    }

    &-border-8 {
      border-radius: 8px;
    }

    &-border-4 {
      border-radius: 4px;
    }
  }

  @for $i from 1 through 100 {
    .#{$prefix}-lg {
      &-w-#{$i} {
        width: calc($i * 1%) !important;
      }
    }
    .#{$prefix} {
      &-h-#{$i} {
        height: calc($i * 1vh) !important;
      }
    }
  }
}

@include breakpoint(desktop) {
  .#{$prefix}-xl {
    &-border-circle {
      border-radius: 50%;
    }

    &-border-8 {
      border-radius: 8px;
    }

    &-border-4 {
      border-radius: 4px;
    }
  }

  @for $i from 1 through 100 {
    .#{$prefix}-xl {
      &-w-#{$i} {
        width: calc($i * 1%) !important;
      }
    }
    .#{$prefix} {
      &-h-#{$i} {
        height: calc($i * 1vh) !important;
      }
    }
  }
}

@include breakpoint(bigscreen) {
  .#{$prefix}-2xl {
    &-border-circle {
      border-radius: 50%;
    }

    &-border-8 {
      border-radius: 8px;
    }

    &-border-4 {
      border-radius: 4px;
    }
  }

  @for $i from 1 through 100 {
    .#{$prefix}-2xl {
      &-w-#{$i} {
        width: calc($i * 1%) !important;
      }
    }
    .#{$prefix} {
      &-h-#{$i} {
        height: calc($i * 1vh) !important;
      }
    }
  }
}

// Border color
@each $color, $value in $colors {
  .#{$prefix} {
    &-border-#{$color} {
      border: 1px solid $value !important;
    }

    &-border-top-#{$color} {
      border-top: 1px solid $value !important;
    }

    &-border-bottom-#{$color} {
      border-bottom: 1px solid $value !important;
    }

    &-border-left-#{$color} {
      border-left: 1px solid $value !important;
    }

    &-border-right-#{$color} {
      border-right: 1px solid $value !important;
    }
  }
}
